<template>
  <BaseCard
    :footer-visible="false"
    :caption-visible="false"
  >
    <template #title>
      <span class="h3 font-weight-bold mb-0 text-uppercase">Informe cartera service</span>
    </template>
    <JobReportsServicesTable  />
  </BaseCard>
</template>


<script>
import JobReportsServicesTable from '@/components/jobs-reports-services/table/JobReportsServicesTable.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  components: { BaseCard, JobReportsServicesTable  },
}
</script>
