<template>
  <div>
    <BaseTable
      ref="job-reports-services-table"
      :resourceStore="'jobReportsServices'"
      :columns="columns"
      :resource="$data.$constants.RESOURCES.RESOURCE_JOBS_REPORTS_SERVICES"
      :loading="loading"
      :filters="filters"
      :hideTotals="true"
      @data-loaded="$emit('data-loaded', $event)"
      @filter-button-click="filtersVisible = !filtersVisible"
      @on-clear-active-filters="handleClearListFilters"
    >
      <template #cell(status)="data">
        <StatusBadge
          :status="data.item.status.name"
        />
      </template>
      <template #cell(total_sale)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(total_cost)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(expected_cost)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(issued_invoices)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(received_invoices)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(issued_wallet)="data">
        {{ data.value | numberToLocalString }} €
      </template>
      <template #cell(received_wallet)="data">
        {{ data.value | numberToLocalString }} €
      </template>
    </BaseTable>
    <JobReportsServicesListFilters
      ref="job-reports-services-list-filters"
      v-model="filtersVisible"
      @filters-submit="$store.commit('jobReportsServices/setFilters', $event)" 
    />
  </div>
</template>

<script>
import BaseTable from "@/components/ui/table/BaseTable.vue";
import StatusBadge from "@/components/status/badge/StatusBadge.vue";
import JobReportsServicesListFilters from '@/components/jobs-reports-services/filters/JobReportsServicesListFilters.vue'
import { mapGetters } from "vuex";

export default {
  name: "JobReportsServicesTable",
  components: {
    StatusBadge,
    BaseTable,
    JobReportsServicesListFilters
  },
  data () {
    return {
      loading: false,
      filtersVisible: false
    }
  },
  computed: {
    ...mapGetters({
      filters: "jobReportsServices/getFilters",
    }),
    columns() {
      return [
        {
          label: "REFERENCIA",
          key: "code",
          sortable: false,
        },
        {
          label: "FECHA",
          key: "job_date",
          sortable: false,
          thStyle: { "min-width": "150px" },
        },
        {
          label: "NOMBRE",
          key: "name",
          sortable: false,
        },
        {
          label: "ESTADO",
          key: "status",
          sortable: false,
        },
        {
          label: "VENTA",
          key: "total_sale",
          sortable: false,
        },
        {
          label: "COSTE TEORICO",
          key: "total_cost",
          sortable: false,
        },
        {
          label: "COSTES PREVISTOS",
          key: "expected_cost",
          sortable: false,
        },
        {
          label: "FACTURAS EMITIDAS",
          key: "issued_invoices",
          sortable: false,
        },
        {
          label: "FACTURAS RECIBIDAS",
          key: "received_invoices",
          sortable: false,
        },
        {
          label: "CARTERA EMITIR",
          key: "issued_wallet",
          sortable: false,
        },
        {
          label: "CARTERA RECIBIR",
          key: "received_wallet",
          sortable: false,
        },
      ];
    }
  },
  methods: {
    handleClearListFilters() {
      this.$store.commit("jobReportsServices/setFilters", {});
      this.$refs["job-reports-services-list-filters"].clearFilters();
    },
  }
}
</script>